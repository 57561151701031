import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const LandingGreySec = styled.div<any>`
  background: #f5f5f5;
  padding: ${(props) => props.padding};
  margin-top: -120px;
  margin-bottom: ${(props) => props.marginBottom};

  @media only screen and (max-width: 768px) {
    padding: ${(props) => props.padding || "16px"};
    margin-top: ${(props) => props.marginTop || "0px"};
  }
`;

export const LandingGreySecWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  background: #f5f5f5;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const LandingWhiteSec = styled.div<any>`
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  // padding: 84px 0;
  padding: ${(props) => props.padding};

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;
