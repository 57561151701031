import styled from "styled-components";

interface ILandingFaqStyled {
  customFaq?: boolean;
}

export const LandingFaq = styled.section<ILandingFaqStyled>`
  display: flex;
  background: #fff;
  flex-direction: column;

  ${(props) => props.customFaq && ``}
`;

export const LandingFaqTitle = styled.h3`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 56px;
  line-height: 72px;
  margin-bottom: 40px;
  color: #000000;
  width: 990px;
`;

export const LandingFaqBody = styled.div`
  padding: 0 24px 24px;
`;
export const LandingFaqListItem = styled.li`
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  h5 {
    padding: 24px;
  }

  &::after {
    content: "";
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    right: 24px;
    top: 33px;
    transition: 0.3s ease-in;
  }
  &.close:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.open:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &:hover {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }
  &:last-child {
    margin-bottom: unset;
  }
`;

export const LandingFaqList = styled.ul<ILandingFaqStyled>`
  width: 1000px;
  gap: 16px;
`;

export const LandingFaqQues = styled.h5<ILandingFaqStyled>`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  ${(props) =>
    props.customFaq &&
    `
font-family: 'Open Sans';
font-size: 12px;
line-height: 16px;
color: #000000;`}
`;
