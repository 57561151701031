import { useState } from "react";
import { LandingWhiteSec } from "../../shared-styled-components";
import * as S from "./LandingFaq.style";
import { PlainMarkdown } from "../markdown";

interface IFaq {
  ques?: string;
  info?: string;
}
interface ILandingFaq {
  title?: string;
  data?: Array<IFaq>;
}
const LandingFaq = ({ title, data }: ILandingFaq) => {
  const [exp, setExp] = useState(-1);

  const handleClick = (index: number) => {
    exp != index ? setExp(index) : setExp(-1);
  };

  return (
    <LandingWhiteSec id="landing_faq_section" padding="137px 0 84px 0">
      <S.LandingFaq>
        {title && <S.LandingFaqTitle>{title}</S.LandingFaqTitle>}
        <S.LandingFaqList>
          {data?.map((item: IFaq, index: number) => {
            return (
              <S.LandingFaqListItem
                className={`${exp == index ? "open" : "close"}`}
                key={index}
                onClick={() => handleClick(index)}
              >
                <S.LandingFaqQues>{item.ques}</S.LandingFaqQues>
                {exp === index && item.info && (
                  <S.LandingFaqBody>
                    <PlainMarkdown>{item.info}</PlainMarkdown>
                  </S.LandingFaqBody>
                )}
              </S.LandingFaqListItem>
            );
          })}
        </S.LandingFaqList>
      </S.LandingFaq>
    </LandingWhiteSec>
  );
};

export default LandingFaq;
