import ReactMarkdown from "react-markdown";
import { StyledMarkdown } from "./styled";

interface IMarkdown {
  children?: string;
  resetStyles?: boolean;
}
export const Markdown = ({ children, resetStyles = false }: IMarkdown) => {
  const content = children ? <ReactMarkdown>{children}</ReactMarkdown> : null;
  return resetStyles ? <StyledMarkdown>{content}</StyledMarkdown> : content;
};

export const PlainMarkdown = ({ children, resetStyles = true }: IMarkdown) => {
  return <Markdown resetStyles={resetStyles}>{children}</Markdown>;
};
