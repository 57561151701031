import styled from "styled-components";

export const StyledMarkdown = styled.div`
  line-height: 1.4;
  margin-top: 15px;
  p,
  ol {
    margin: 1em 0;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  li {
    margin: 0.5em 0;
  }
`;
